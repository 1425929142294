div.nav-parent {
    // backgroundColor: "purple",
    display: flex;
    flex-direction: row;
}

nav.side-nav {
    height: 100%;
    display: flex;
}


nav.side-nav.light {
    color: var(--primary-secondary);
    background-color: var(--off-white);
    border-color: var(--primary-background);
}

nav.side-nav.dark {
    color: var(--off-white);
    background-color: var(--dark-bg);
    border-color: var(--primary-background-dark);
}

div.nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    div.nav-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
}

div.nav-icon-container.light {
    color: var(--primary-secondary);
}

div.nav-icon-container.dark {
    color: var(--off-white);
}

div.nav-icon.active {
    div.nav-icon-container {
        color: var(--primary);
    }
    div.nav-icon-container.light {
        background-color: var(--primary-background);
    }
    div.nav-icon-container.dark {
        background-color: var(--primary-background-dark);
    }
}



nav.side-nav-expander {
    // height: 100%;
    // width: 220px;
    padding: 0.5rem;
    border-right: 1px solid;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    
}

nav.side-nav-expander.light {
    border-color: var(--primary-background);
    svg {
        color: var(--primary-secondary);
        transition: background-color var(--quickTransition), color var(--quickTransition);
    }
}

nav.side-nav-expander.dark {
    border-color: var(--primary-background-dark);
    svg {
        color: var(--dark-text);
        transition: background-color var(--quickTransition), color var(--quickTransition);
    }
}

h2.side-nav-break {
    padding: 0rem !important;
    // padding-bottom: 0rem !important;
    margin: 0rem !important;
}

h2.side-nav-break.dark {

}
// media query does not like css variables
$md: 800px;
$md2: 801px;

@media (max-width: $md) {
    nav.side-nav {
        flex-direction: row;
        border: 1px solid;
        border-top: 1px solid transparent;
        gap: 0.5rem;
        height: 50px;
        align-items: center;
        // max-width: 150px;
        width: 100%;
        padding: 0rem 0.5rem
    }

    nav.side-nav.light {
        background-color: var(--bread-crumbs);
        // background-color: var(--header-nav2);
    }

    nav.side-nav.dark {
        background-color: var(--dark-bg-header);
    }

    div.nav-icon {
        div.nav-icon-container {
            height: calc(var(--nav-height) - 1rem);
            width: calc(var(--nav-height) - 1rem);
        }
        svg {
            height: 20px;
            width: 20px;
        }
    }
    nav.side-nav-expander {
        position: absolute;
        z-index: 1000;
        top: 99px;
        border: 1px solid;
        height: calc(100vh - var(--nav-height)*2);
        max-height: calc(100vh - var(--nav-height)*2);
        overflow: auto;
        width: 220px;
    }

    nav.side-nav-expander.light {
        background-color: var(--off-white);
        box-shadow: 0rem 0rem 8px 0px var(--shadow-light);
    }

    nav.side-nav-expander.dark {
        background-color: var(--dark-bg);
        box-shadow: 0rem 0rem 8px 0px var(--shadow-dark);
    }

    nav.side-nav-expander::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }
    

    div.side-nav-expander-btn {
        position: absolute;
        z-index: 1000;
        top: 99px;
        // background-color: var(--off-white);
        // background-color: var(--bread-crumbs);
        border: 1px solid var(--primary-background);
        border-top: 1px solid var(--bread-crumbs);
        // height: 24px;
        // width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        // color: var(--primary-secondary);
        padding: 0.25rem 1rem;
        box-shadow: 2px 2px 4px 0px var(--shadow-light);


        svg {
            height: 20px;
            width: 20px;
        }
    }
    div.side-nav-expander-btn.light {
        background-color: var(--bread-crumbs);
        border: 1px solid var(--primary-background);
        border-top: 1px solid var(--bread-crumbs);
        color: var(--primary-secondary);
        box-shadow: 2px 2px 4px 0px var(--shadow-light);
    }
    div.side-nav-expander-btn.dark {
        background-color: var(--dark-bg-header);
        border: 1px solid var(--primary-background-dark);
        border-top: 1px solid var(--primary-background-dark);
        color: var(--off-white);
        box-shadow: 2px 2px 4px 0px var(--shadow-dark);
    }
    .side-nav-option {
        font-size: 0.75rem;
        svg {
            height: 16px;
            width: 16px
        }
    }
}

@media (min-width: $md2) {
    nav.side-nav {
        flex-direction: column;
        border-right: 1px solid var(--primary-background);
        gap: 0.5rem;
        background-color: var(--off-white);
    }
    
    div.nav-icon {
        padding: 0.5rem;
        div.nav-icon-container {
            height: calc(var(--nav-height) - 1rem);
            width: calc(var(--nav-height) - 1rem);
        }
        svg {
            height: 24px;
            width: 24px;
        }
    }
    nav.side-nav-expander {
        height: 100%;
        width: 220px;
    }

    .side-nav-option {
        font-size: 0.85rem;

        svg {
            height: 20px;
            width: 20px
        }
    }

    .side-nav-option:hover {
        svg {
            color: var(--primary);
        // color: var(--header-nav-secondary);
        }
        color: var(--primary);  
        // background-color: var(--primary-background);
        // color: var(--header-nav-secondary);
    }
    .side-nav-option.light:hover {
        background-color: var(--header-nav);
    }
    .side-nav-option.dark:hover {
        // background-color: rgba(80, 93, 240, 0.141);
        background-color: var(--primary-secondary-dark);
    }
}

.side-nav-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    // font-size: 0.85rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color var(--quickTransition), color var(--quickTransition);
    text-decoration: none;
}

.side-nav-option.light {
    color: var(--dark-gray);
}

.side-nav-option.dark {

    color: var(--off-white);
}

.side-nav-option.active {
    svg {
        color: var(--primary) !important;
    }

    color: var(--primary) !important;
}

.side-nav-option.light.active {
    background-color: var(--primary-background) !important;
}

.side-nav-option.dark.active {
    background-color: var(--primary-background-dark) !important;
}



div.side-nav-sub-group-wrapper {
    position: relative;
    cursor: pointer;

    div.side-nav-sub-group-parent {
        
        display: grid;
        grid-template-columns: calc(100% - 20px) 20px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    div.side-nav-sub-group {
        // display: none;
        opacity: 0;
        // opacity: 1;
        display: flex;
        position: absolute;
        left: calc(100% - 0.5rem);
        // top: calc(50%);
        z-index: 1000;
        align-items: center;
        width: max-content;
        font-size: 0.8rem;
        pointer-events: none;
        // transform: ;

        div.side-nav-sub-group-arrow {
            width: 10px;
            height: 16px;
            border-color: transparent var(--primary-background) transparent transparent;
            border-style: solid;
            border-width: 7px;
        }
    
        div.side-nav-sub-group-text {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            border-radius: 4px;
            border: 1px solid;
            width: 150px;
        }

        div.side-nav-sub-group-text.light {
            background-color: #f5f6f8;
            border-color: var(--primary-background);
            box-shadow: 0rem 0rem 4px 0px var(--shadow-light);
            .side-nav-option {
                color: var(--dark-bg-label);
            }
        }

        div.side-nav-sub-group-text.dark {
            background-color: var(--dark-bg-header);
            border-color: var(--primary-background-dark);
            box-shadow: 0rem 0rem 4px 0px var(--shadow-dark);
            .side-nav-option {
                color: var(--primary-secondary);
            }
        }
    }
}

div.side-nav-sub-group-wrapper:hover{
    pointer-events: all;
    div.side-nav-sub-group {
        opacity: 1;
        pointer-events: all;
        display: flex;
    }
}

div.side-nav-sub-group:hover {
    pointer-events: all;
    div.side-nav-sub-group {
        opacity: 1;
        pointer-events: all;
        display: flex;
    }
}

.side-nav-sub-group-arrow {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    transition: background-color var(--quickTransition), color var(--quickTransition);
}

.side-nav-sub-group-arrow.active {
    svg {
        color: var(--primary);
    }
    background-color: var(--primary-background);
    
}

.side-nav-sub-group-arrow.light.active {
    background-color: var(--primary-background);
    
}

.side-nav-sub-group-arrow.dark.active {
    background-color: var(--primary-background-dark);
    
}

div.side-nav-sub-group-dropdown {
    display: none;
    margin-left: 0.5rem;
    
}

div.side-nav-sub-group-dropdown.active {
    display: block;
}

// div.side-nav-sub-group-wrapper:hover {
//     div.side-nav-sub-group {
//         display: flex;
//     }
// }

// div.side-nav-sub-group:hover {
//     div.side-nav-sub-group {
//         display: flex;
//     }
// }