@use "../../@fair/src/scss/vars";

div.pop-up-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    transition: background-color 0.25s ease;
}

div.pop-up-mask.fade {
    background-color: rgba(0,0,0,.35);
}

div.pop-up-container {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    pointer-events: none;
    // opacity: 0;
    z-index: 1001;
    padding: 1rem;
    // background-color: red;
}

div.pop-up {
    // border: 1px solid var(--dark-gray);
    padding: 0.5rem;
    background-color: var(--off-white);
    border-radius: var(--border-radius);
    box-shadow: 0.5rem 0.5rem 8px grey;
    pointer-events: all;
    overflow: auto;
    min-width: 250px;
    min-height: 150px;
    max-width: 532px;
    max-height: 650px;
    scale: 0;
    opacity: 0;
    pointer-events: none;
    transition: scale 0.25s ease, left 0.5s ease, opacity 0.25s ease;
    display: flex;
    flex-direction: column;
}

div.pop-up.fade {
    scale: 1;
    opacity: 1;
    pointer-events: all;
}

div.pop-up.dark {
    border-color: var(--dark-bg);
    background-color: var(--dark-bg);
    box-shadow: 0.5rem 0.5rem 8px var(--dark-bg)-header;
}

button.pop-up-close {
    position: absolute;
    // top: 0.5rem;
    right: 0.5rem;
    margin: 0.5rem !important;
}

div.pop-up-contents {
    // padding: 1rem;
    overflow: auto;
}

.pop-up-title {
    padding: 1.5rem 1rem 0.5rem 1rem!important;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
}

div.pop-up-text {
    // padding: 0.5rem 1rem calc(0.5rem + 57px) 1rem!important;
    // padding: 0.5rem;
    padding: 1rem 1rem 1.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 400;
}

div.pop-up-footer {
    // position: absolute;
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    bottom: 0rem;
    width: 100%;
} 

div.pop-up-options {
    display: flex;
    // justify-content: space-between;
    // justify-content: space-evenly;
    // justify-content: start;
    // justify-content: end;
    flex-direction: row;
}


@media (min-width: 450px) {
    div.pop-up {
        min-width: 350px;
    }
}

