@use "vars";

p.color-label {
    padding:       .2rem .75rem;
    display:       inline-block;
    // border-radius: 1rem;
    border-radius: 4px;
    font-weight:   500;
    font-size:     .8rem;
    user-select:   none;
    white-space:   nowrap;
}