div.header-nav {
    min-height: var(--nav-height);
    background-color: var(--header-nav2);
    border-bottom: 1px solid var(--primary-background);
    width: 100%;
}

div.header-nav.dark {
    background-color: var(--dark-bg2);
}

header.header-nav-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 10px;

    div.header-nav-title {
        padding: 0.5rem;
        height: 34px;
        // width: 34px;
        // background-color: var(--header-nav-secondary);
        // border: 1px solid var(--primary);
        // border-radius: 100%;
        color: var(--header-nav-secondary);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 700;
    }
    
    div.header-nav-profile {
        --dim: 30px;
        --prof-padding: calc((50 - var(--dim))*2px);
        // padding: var(--prof-padding);
        height: var(--dim);
        width: var(--dim);
        background-color: var(--header-nav-secondary);
        box-shadow: var(--box-shadow-light);
        // border: 1px solid var(--primary);
        border-radius: 100%;
        color: var(--off-white);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: .9rem;
        
    }
}

div.profile-menu {
    position:                  absolute;
    top:                       calc(var(--nav-height) - 1rem);
    right:                     0;
    width:                     300px;
    // height:                    300px;
    // background-color:          var(--dark-bg);
    opacity:                   0;
    padding:                   calc(var(--padding) * 0.5);
    pointer-events:            none;
    z-index:                   100;
    transition:                top 0.2s ease, opacity 0.2s ease, color .5s ease, background .5s ease;
    border-bottom-left-radius: 0.6rem;
    border: 1px solid;
    border-top: 1px solid transparent;

    p {
        // color: var(--off-white);
        text-align: center;
    }

    p.profile-icon-text {
        // color: var(--off-white);
    }

    p.fullname {
        font-size:  1.4rem;
        margin-top: .4rem;
        overflow-wrap: break-word;
    }

    p.username {
        margin-top:  -.5rem;
        // color:       #E3E3E3 !important;
        font-weight: 300;
    }

    div.profile-icon-large {
        height:        100px;
        width:         100px;
        // background:    var(--primary);
        border-radius: 50%;
        position:      relative;
        left:          50%;
        transform:     translateX(-50%);
        transition: background-color 0.2s ease;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--header-nav-secondary);
        box-shadow: var(--box-shadow-light);
        p {
            font-size:      3rem;
            // position:       relative;
            // top:            50%;
            // transform:      translateY(-50%);
            letter-spacing: 1px;
            user-select:    none;
        }
    }

    div.profile-icon-large.org {
        background-color: var(--primary);
        // border: 4px solid var(--primary);
    }

    div.button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.25rem;
    }
}

div.profile-menu.light {
    box-shadow: -2px 2px 4px 0px var(--shadow-light);
    background-color: var(--bread-crumbs);
    border-color: var(--primary-background);
}

div.profile-menu.dark {
    box-shadow: -2px 2px 4px 0px var(--shadow-dark);
    background: var(--dark-bg);
    border-color: var(--primary-background);
}


button.profile-menu {
    // background-color: var(--off-white) !important;
}

button.theme.profile-menu.light {
    box-shadow: -2px 2px 4px 0px var(--shadow-light);
    border: 1px solid var(--primary-background);
    color: var(--off-white);
    background-color: var(--dark-gray);
}

button.theme.profile-menu.dark {
    box-shadow: -2px 2px 4px 0px var(--shadow-dark);
    color: var(--dark-gray);
    border: 1px solid var(--primary-background);
}
// div.profile-icon-large

// div.profile-menu.dark {
//     background-color: var(--dark-bg-2);
// }

div.profile-menu.active {
    // top:            68px;
    top: var(--nav-height);
    opacity:        1;
    pointer-events: all !important;
}