@use "./@fair/src/scss/vars";

:root {
    --primary: #5F6BF3;
    --off-white: #FFFFFF;
    --light-gray: #B0B0B0;
    --off-gray: #7C7C7C;
    --dark-gray: #1A1A1A;
    --dark-gray2: #454545;
    --dark-bg: #10111C;
    --dark-bg2: #0B0C13;
    --dark-bg-secondary: #1D1F34;
    --dark-bg-tertiary: #51558F;
    --dark-bg-label: #686A94;
    --dark-bg-footer: #2F3154;
    --dark-bg-header: #151726;
    --dark-text: #afb3dc;
    --light-bg-footer: #A7A8BD;
    --light-bg-header: #F0F0F0;
    --success: #58C718;
    // --error: #FF3333;
    --error: #fe5461;
    --warning: #FABE1B;
    --scrollbar: #5f6bf3a4;

    // --test: #f6f6f8;
    --header-nav: #d6d9f758;
    --header-nav2: #f1f2ff;
    // --header-nav2: #e3e5ff;
    --header-nav-secondary: #5f6bf3a2;
    --test2: #ededf0;
    // --bread-crumbs: #daddf52b;
    --bread-crumbs: #f9faff;
    // --bread-crumbs: #f1f2ff;


    --shadow-light: #cdcdcd;
    --box-shadow-light: 0px 2px 1px -5px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --box-shadow-dark: 0px 2px 1px -5px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    --shadow-dark: #1c1e2f;
    --shadow-dark2: #171421;

    --primary-secondary: #898b96;
    --primary-background: #5f6bf323;
    --primary-background-dark: #818af023;
    --primary-secondary-dark: #818af017;

    --border-light: var(--primary-background);

    --border-radius: 3px;
    --padding: 1rem;

    --nav-height: 2rem;

    --font: "IBM Plex Sans", sans-serif, serif, Arial;

    --xs: 400px;
    --sm: 600px;
    --md: 800px;
    --md2: 801px;
    --lg: 1000px;
    --xl: 1200px;
    --xl2: 1600px;
    --xl3: 2000px;

    --themeTransition: 0.5s ease;
    --quickTransition: 0.1s ease;

    --nav-height: 50px;
}

* {
    scrollbar-color: var(--scrollbar);
}

div.container#content {
    width: 100%;
    // margin-top: 68px;
    // transition: width 0.5s ease;
    // height: calc(100vh - var(--nav-height))

}

div.container#content.light {
    // background-color: #fafafa;
}


div.content-2 {
    overflow-y: auto;
}

div.container.auth#content {
    margin-top: 0px;
}

div.container#content.fullscreen {
    width: 100% !important;
}

div#shell {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

div#content-nav-container {
    display: flex;
}

// media query does not like css variables
$md: 800px;
$md2: 801px;

@media (max-width: $md) {
    div#content-nav-container {
        flex-direction: column;
    }

    div#content:not(.auth) {
        div.content-2 {
            height: calc(100vh - var(--nav-height)*2);
            // this is to prevent scrolling issues with the mobile bar in certain browsers
            padding-bottom: 6rem;
        }
    }

    div#content.auth {
        div.content-2 {
            height: 100vh;
            // this is to prevent scrolling issues with the mobile bar in certain browsers
            padding-bottom: 6rem;
        }
    }
}

@media (min-width: $md2) {
    div#content-nav-container {
        flex-direction: row;
    }

    div.content-2 {
        height: calc(100vh - var(--nav-height)*2);
    }

    div.container.expanded#content {
        width: calc(100% - 270px);
        // margin-top: 68px;
        // transition: width 0.5s ease;
        // height: calc(100vh - var(--nav-height))

    }

}

// test to make more responsive 
// @media (min-width: 100px) {
//     div.container#content {
//         width:      calc(100% - 0px) !important;
//     }
// }
// @media (min-width: vars.$sm) {
//     div.container#content {
//         width:      calc(100% - 0px) !important;
//     }
// }
// @media (min-width: vars.$md) {
//     div.container#content {
//         width:      calc(100% - 120px) !important;
//     }
// }

// @media (min-width: vars.$lg) {
//     div.container#content {
//         width:      calc(100% - 200px) !important;
//     }
// }

// @media (min-width: vars.$xl) {
//     div.container#content {
//         width:      calc(100% - 280px) !important;
//     }
// }


// ************ SCROLLBAR ************

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

// ::-webkit-scrollbar-track {
//     background: vars.$light-bg-header;
// }

// ::-webkit-scrollbar-thumb {
//     background: var(--dark-bg)-footer;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: var(--dark-bg-tertiary);
// }


// ::-webkit-scrollbar-track {
//     background: var(--dark-bg-secondary);
// }

::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar);
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

// ************ SCROLLBAR ************


// ************ REACT-TOGGLE ************
.react-toggle-thumb {
    border: none !important;
    box-shadow: none !important;
}

.react-toggle--checked>.react-toggle-track {
    background-color: var(--primary) !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--scrollbar) !important;
}

.react-toggle:hover:not(.react-toggle--disabled, .react-toggle--checked) .react-toggle-track {
    background-color: #353535 !important;
}