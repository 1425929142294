@use "vars" as vars;

div.table-wrapper-3 {
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    // overflow: visible
    // overflow: auto;
    overflow: visible;
    max-width: 100%;
    position: relative;
    border-radius: 4px;
}

div.table-wrapper-2 {
    border-radius: 8px;
    border: 1px solid;
    box-shadow: 0px 2px 1px -5px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

// thead, th {
//     position: sticky !important;
//     top: 80px;
//     z-index: 10;
//     // overflow-x: scroll;
// }

div.table-wrapper {
    width: 100%;
    overflow-x: scroll;
    border-radius: 4px;
    -webkit-overflow-scrolling: touch;
}

div.table-wrapper-2.light {
    border-color: var(--primary-background);
}

div.table-wrapper-2.dark {
    border-color: var(--primary-background-dark);
}

// media query does not like css variables
$md: 800px;
$md2: 801px;

div.table-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
}

div.table-title-container-2 {
    position: sticky;
    z-index: 10;
    top: -1px;
}

div.table-title-container-2.pinned {
    box-shadow: 0px 2px 1px -5px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding-bottom: 0.5rem;
}


div.table-title-container-2.light {
    background-color: vars.$off-white;
}

div.table-title-container-2.dark {
    background-color: vars.$dark-bg;
}


h1.table-title {
    font-weight: 300;
}


div.table-title-container.dense {
    padding: 1.0rem 1.0rem 0.5rem !important;
    gap: 0.5rem !important;
}

h1.table-title.dense {
    font-size: 1.3rem !important;
    line-height: 1.3rem !important;
}

div.row-select-display.dense {
    padding: 1.0rem 1.0rem 0.5rem !important;
}

td.v2.dense > :nth-child(1) {
    padding: calc(var(--padding) / 2) !important;
    font-size: 0.8rem !important;
}

td.v2.dense:first-child> :nth-child(1) {
    padding-left: calc(var(--padding)) !important;
}

td.v2.dense:last-child> :nth-child(1) {
    padding-right: calc(var(--padding)) !important;
}

th.dense {
    // padding: 0rem;
    padding: calc(var(--padding) / 2)
}

th.dense:first-child {
    padding-left: 1rem !important;
}

th.dense:last-child {
    padding-right: 1rem !important;
}


@media (max-width: $md) {
    div.table-title-container {
        padding: 1.0rem 1.0rem 0.5rem;
        gap: 0.5rem;
    }

    h1.table-title {
        font-size: 1.3rem;
        line-height: 1.3rem;
    }

    div.row-select-display {
        padding: 1.0rem 1.0rem 0.5rem;
    }
}

@media (min-width: $md2) {
    div.table-title-container {
        padding: 1.5rem 2.0rem 1.0rem;
        gap: 0.5rem;
    }

    h1.table-title {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    div.row-select-display {
        padding: 0rem 2.0rem 0rem;
    }
}

table {
    width: 100%;
    font-size: .8rem;
    border-collapse: collapse;

    // border-collapse: separate !important;
    border-spacing: 0px;

    thead tr th.parent {
        text-align: center !important;
        font-size: 1.2rem;
        font-weight: 400;
        display: none;

        svg {
            display: none;
        }
    }

    thead tr th {
        position: relative;
        // padding:    calc(var(--padding)*1.3/2) calc(var(--padding)/2);
        transition: background vars.$themeTransition, color vars.$themeTransition, border-color vars.$themeTransition !important;
    }

    .th {
        padding: 1.0rem;
    }

    div.table-header-text {
        display: flex;
        gap: 0.5rem;
        // justify-content: space-between;
        // justify-items: center;
        // align-items: center;
    }

    th#table-actions {

        div.table-header-text {
            justify-content: left !important;
            // justify-items: center;
            // align-items: center;
        }
    }

    div.table-header-text.can-sort {
        // cursor:      pointer;
        user-select: none;
    }

    div.table-header-text.can-sort>*>svg {
        cursor: pointer;
        user-select: none;
    }

    div.table-header-text.light {
        color: var(--dark-gray) !important;
        // padding: 0;
    }

    div.table-head-text.dark {
        color: var(--off-white) !important;
    }

    div.table-filter-sort-container {
        display: flex;
        align-items: center;
        column-gap: 2px;
    }

    th:hover {
        svg.expander {
            opacity: 1;
            pointer-events: all;
        }
        svg.table-sort {
            opacity: 1;
            pointer-events: all;
        }

        svg.table-filter-icon {
            opacity: 1;
            pointer-events: all;
        }
    }

    svg.table-filter {
        // position:   absolute;
        // top:        50%;
        // transform:  translateY(-50%);
        // right:      var(--padding);
        transition: color vars.$themeTransition !important;
    }

    svg.table-filter:hover {
        color: var(--primary);
    }
    
    svg.expander {
        position: relative;
        opacity: 0;
        pointer-events: none;
    }

    svg.expander.active {
        opacity: 1;
        pointer-events: all;    
        color: var(--primary);
    }

    svg.table-sort {
        position: relative;
        opacity: 0;
        pointer-events: none;
    }

    svg.table-filter-icon {
        position: relative;
        transition: color vars.$themeTransition !important;
        opacity: 0;
        pointer-events: none;
    }

    svg.table-sort.active {
        color: var(--primary);
        opacity: 1;
        pointer-events: all;
    }

    svg.table-filter-icon.light {
        color: #1A1A1A;
    }

    svg.table-filter-icon.dark {
        color: #FFFFFF;
    }

    svg.table-filter-icon.active {
        color: var(--primary) !important;
        opacity: 1;
        pointer-events: all;
    }

    thead tr th {
        text-align: left;
        user-select: none;
    }

    thead tr:first-child {
        text-align: center;
        user-select: none;
    }

    th {
        padding: calc(var(--padding) / 2);
        font-weight: 500;
        // border-right: 1px solid #E3E3E3;
        white-space: nowrap;
    }

    th:last-child {
        border-right: 1px solid transparent;
    }

    th.dark {
        // border-right: 1px solid #191A2D;
    }

    th.dark:last-child {
        border-right: 1px solid transparent;
    }

    tbody tr {
        // border-radius: 50%;
        border-radius: 2px;
        transition: background vars.$themeTransition;
    }

    tr.spacer {
        height: calc(var(--padding) * 0.5) 0;
    }

    td {
        margin-top: 1rem !important;
        padding: calc(var(--padding)/2) calc(var(--padding)*3/2);
        transition: background vars.$themeTransition, color vars.$themeTransition !important;
        font-size: .85rem;
        font-weight: 400;
    }

    td.v2 {
        padding: 0 !important;
        // padding: 0.5rem 0;
        transition: border-right-color vars.$themeTransition !important;
        white-space: normal;
        max-width: 400px;
        position: relative;
    }

    // th.pinned {
    //     z-index: 11;
    // }

    .pinned {

        // box-shadow: 2px 0 2px -2px gray inset;

        opacity: 0.8;
        position: sticky !important;
        z-index: 1,
    }

    // th {
    //     padding: 1rem;
    // }

    // th:first-child {
    //     padding-left: 2rem;
    // }

    // th:last-child {
    //     padding-right: 2rem;
    // }

    th.light {
        background-color: vars.$off-white;
    }

    td.v2.light {
        background-color: vars.$off-white;
    }

    th.v2.dark {
        background-color: vars.$dark-bg;
    }

    td.v2.dark {
        background-color: vars.$dark-bg;
    }

    // td.v2 > :nth-child(1) {
    //     padding: calc(var(--padding) );
    // }

    // td.v2:first-child > :nth-child(1) {
    //     padding-left: calc(var(--padding)*2 );
    // }

    // td.v2:last-child > :nth-child(1){
    //     padding-right: calc(var(--padding)*2 );
    // }

    td.v2 {
        // border-right: 1px solid #E3E3E3;
    }

    td.v2:last-child {
        // border-right: 1px solid transparent;
    }

    td.v2.dark {
        // border-right-color: #191A2D;
    }

    td.v2.dark:last-child {
        border-right-color: transparent;
    }

    // tbody.dark tr:nth-of-type(odd) {
    //     background: #161828FF
    // }
    //
    // tbody.light tr:nth-of-type(odd) {
    //     background: #F9F9F9
    // }

    tbody tr {
        transition: border-color vars.$themeTransition !important;
    }

    tbody tr {
        border-top: 1px solid transparent;
    }

    tbody tr:last-child {
        border-bottom: 1px solid transparent;
    }

    tbody.light tr {
        border-top-color: #E3E3E3;
    }

    tbody.light tr:last-child {
        border-bottom-color: #E3E3E3;
    }

    tbody.dark tr:last-child {
        border-bottom-color: #191A2D;
    }

    tbody.dark tr {
        border-top-color: #191A2D;
    }

    tbody.dark tr:last-child {
        border-top-color: #191A2D;
    }

    tbody.dark td {
        color: #AFB3DC;
    }

    tbody.light td {
        color: #404040;
    }

    tr.active {
        cursor: pointer;
    }

    tr.light.active:hover {
        td.v2 {
            background-color: #EEEEEE !important;
        }
    }

    tr.dark.active:hover {
        td.v2 {
            background-color: var(--dark-bg-secondary) !important;
        }
    }

    tr.light.selected>td.v2 {
        background-color: var(--header-nav2);
    }

    tr.dark.selected>td.v2 {
        background-color: var(--dark-bg-footer);
    }

    @media (max-width: $md) {
        td.v2> :nth-child(1) {
            padding: calc(var(--padding) / 2);
            font-size: 0.8rem;
        }

        td.v2:first-child> :nth-child(1) {
            padding-left: calc(var(--padding));
        }

        td.v2:last-child> :nth-child(1) {
            padding-right: calc(var(--padding));
        }

        th {
            // padding: 1rem;
        }

        th:first-child {
            padding-left: 1rem;
        }

        th:last-child {
            padding-right: 1rem;
        }

    }

    @media (min-width: $md2) {
        td.v2> :nth-child(1) {
            padding: calc(var(--padding));
        }

        td.v2:first-child> :nth-child(1) {
            padding-left: calc(var(--padding)*2);
        }

        td.v2:last-child> :nth-child(1) {
            padding-right: calc(var(--padding)*2);
        }

        th {
            padding: 1rem;
        }

        th:first-child {
            padding-left: 2rem;
        }

        th:last-child {
            padding-right: 2rem;
        }

    }
}

div.table-date-range {
    width: 32px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-background);
    padding: 0.25rem;
    border-radius: 4px;
    cursor: pointer;

    svg {
        width: 24px;
        height: 24px;
        color: var(--primary);
    }
}

div.pagination-container {
    padding: 1rem 1rem 1rem 1rem;
    row-gap: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .filler {
        // display: none;
    }

    .pagination {
        display: flex;
        flex-direction: row;
        gap: 0.15rem;
        flex-wrap: wrap;
        justify-content: center;

        button.pagination-btn {
            border-radius: 100%;
            height: 32px;
            width: 32px;
            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none !important;
            color: #5F6BF3;
        }

        div.input-container.pagination-page {
            margin: 0 !important;
        }

    }

    .pagination-section {
        flex: 1
    }

    .row-select {
        display: flex;
        justify-content: flex-end;

        div.select-wrapper {
            margin: 0 !important;
        }
    }


    // @media (max-width: $md) {
    //     .filler {
    //         display: none;
    //     }
    //     .pagination-container {
    //         flex: none;
    //     }
    // }

    // @media (min-width: $md2) {
    //     .pagination-section {
    //         flex: 1;
    //     }
    // }
}

div.table-action-parent.light {
    // background-color: rgb(248,248,248) !important;
}

div.table-action-parent {
    transform-origin: top;
    scale: 1 0;
    position: absolute;
    border-radius: vars.$border_radius;
    background-color: vars.$off-white;
    box-shadow: 0 0 0.75rem grey;
    z-index: 11;
    transition: scale 0.25s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.table-action-options {
    // height: 200px;
    overflow: visible;
    white-space: normal;
}

div.table-action-parent.light {
    background-color: vars.$off-white;
    box-shadow: 0 0 0.75rem grey;
}

div.table-action-parent.dark {
    border: 1px solid vars.$dark-bg-tertiary;
    background-color: vars.$dark-bg-secondary;
    box-shadow: 0 0 0.75rem vars.$dark-bg2;
}

div.table-action-child {
    display: flex;
    position: relative;
    // justifyContent: "space-between",
    align-items: center;
    column-gap: 0.5rem;
    // padding: 0.5rem;
    border-radius: 3px;
    // box-shadow: 0rem 0rem 8px 0px #cdcdcd;
    transition: background .1s ease, border .3s ease, box-shadow 0.3s ease;
}

div.table-action-child.light {
    // background-color: #FFFFFF;
}

div.table-action-child.dark {}


div.table-action-child:hover {
    // box-shadow: 0rem 0rem 16px 0px #cdcdcd;

}

div.table-action-child.light:hover {
    background-color: #EEEEEE;
}

div.table-action-child.dark:hover {
    background-color: vars.$dark-bg-tertiary;
}

div.table-filter {
    // opacity:        0;
    // pointer-events: none;
    // position: fixed;
    margin: 0.5rem 0 0 0 !important;
    width: 100%;
    min-width: 100px;

    input {
        width: 100%;
    }

    position: relative;
    z-index: 10;
}

div.table-filter.active {
    opacity: 1;
    pointer-events: all;
}

// pop up filter

div.pop-up-filter-parent {
    transform-origin: top;
    scale: 1 0;
    position: absolute;
    border-radius: vars.$border_radius;
    background-color: var(--off-white);
    box-shadow: 0 0 0.75rem grey;
    z-index: 1000;
    transition: scale 0.25s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.pop-up-filter-options {
    // height: 200px;
    max-height: 200px;
    overflow: auto;
    white-space: normal;
}

div.pop-up-filter-parent.dark {
    // border: 1px solid var(--dark-bg-tertiary);
    background-color: var(--dark-bg-secondary);
    box-shadow: 0 0 0.75rem var(--dark-bg2);
}

div.pop-up-filter-btn-container {
    display: flex;
    justify-content: space-between;
}

div.pop-up-filter-option {
    display: flex;
    align-items: center;
    cursor: pointer;
}

input.filter-range {
    padding: 0.5rem;
    // padding: 0.5rem 0rem 0rem 0rem;
}

svg.filter-option-arrow {
    margin-right: 0.5rem;
}

svg.filter-option-arrow.month {
    margin-left: 0.5rem;
}

svg.filter-option-arrow.active {
    transform: rotate(90deg);
}

svg.filter-option-arrow.month.active {
    transform: rotate(90deg);
}

input.pop-up-filter-check {
    width: 20px;
    height: 20px;
    accent-color: #5F6BF3;
    margin-right: 0.5rem;
}