@use "../../@fair/src/scss/vars";

div.mapPinIcon {
    height:        15px;
    width:         15px;
    background:    #1A1A1A;
    border-radius: 50%;
    border:        2px solid #FFFFFF;
    transition:    all .2s ease;
}

div.pin {
    cursor:     pointer !important;
    transition: all .2s ease;
    width:      10px;
}

div.pin:hover p.mapPinText {
    color:  var(--primary);
    border: 1px solid var(--primary);
}

div.pin:hover div.mapPinIcon {
    background: var(--primary);
}

div.google-map {
    //margin-top: $padding;
    width:  100%;
    height: 50vh;
}

p.mapPinText {
    position:      absolute;
    top:           2px;
    left:          -20px;
    text-align:    center;
    background:    #FFFFFF;
    color:         #1A1A1A;
    padding:       0 5px;
    border-radius: 3px;
    font-family:   vars.$font;
    border:        1px solid #1A1A1A;
    font-weight:   500;
    transition:    all .2s ease;
}

div.pinInfo {
    height:         auto;
    width:          auto;
    background:     #1A1A1A;
    //border: 1px solid #fff;
    border-radius:  3px;
    position:       relative;
    left:           -43px;
    top:            -50px;
    padding:        5px 7px 7px 7px;
    display:        inline-block;
    white-space:    nowrap;
    opacity:        0;
    pointer-events: none;
    transition:     all .2s ease;
    z-index:        100;
    text-align:     center;
}

.pinInfoActive {
    opacity:        1 !important;
    pointer-events: all !important;
}

p.pinInfoText {
    padding:     0;
    margin:      0;
    line-height: 15px;
    color:       #FFFFFF;
}

div.pinInfoTriangle {
    width:         0;
    height:        0;
    border-left:   7px solid transparent;
    border-right:  7px solid transparent;
    border-bottom: 6px solid #1A1A1A;
    position:      absolute;
    bottom:        -6px;
    left:          50%;
    transform:     translateX(-50%) rotate(180deg);
}
