@use "../../@fair/src/scss/vars";

div.header {
    height: var(--nav-height);
    top: 0px;
    z-index: 10;
    opacity: 0.95;
    position:      sticky;
    width:         100%;
    padding:       0rem var(--padding);
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.breadcrumbs {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
    }

    a.breadcrumb {
        // font-size:      .85rem;
        text-transform: capitalize;
        display:        inline-block;
        user-select:    none;
    }

    a.breadcrumb.light {
        color: var(--dark-bg);
    }

    a.breadcrumb.dark {
        color: #FFFFFF;
    }

    a.breadcrumb.current {
        pointer-events:  none;
        text-decoration: none;
        font-weight:     400;
    }

    a.breadcrumb.current.light {
        color: #696969;
    }

    a.breadcrumb.current.dark {
        color: var(--dark-bg-tertiary);
    }

    svg.breadcrumb-arrow {
        // margin-right: 0.5rem;
        // margin-left:  0.5rem;
        // position:     relative;
        // top:          3px;
        // margin-top: 2px;
    }

    svg.breadcrumb-arrow.dark {
        color: #E3E3E3;
    }

    svg.breadcrumb-arrow.light {
        color: var(--dark-bg);
    }
}



div.header.fullscreen {
    width: 100% !important;
}

// media query does not like css variables
$md: 800px;
$md2: 801px;

@media (max-width: $md) {
    div.header {
        background-color: transparent;
        border-color: transparent;
        height: 30px;
        justify-content: flex-end;
        a.breadcrumb {
            font-size: .75rem;
        }
    }
}

@media (min-width: $md2) {
    div.header {

        a.breadcrumb {
            font-size: .85rem;
        }
    }
    div.header.light {
        // background: var(--light-bg-header);
        background: var(--bread-crumbs);
        border-color: var(--primary-background);
    }
    
    div.header.dark {
        background: var(--dark-bg-header);
    }
}