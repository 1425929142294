@use "../../../@fair/src/scss/vars/" as vars;

.announcement-text {
    max-width: 500px;
    // text-wrap: wrap;
    white-space: break-spaces;
    font-size: 1rem;
    color: #1A1A1A;
    font-weight: 400;
    line-height: 1.5rem;
}

.announcement-text.dark {
    color: #AFB3DC
}