@use "../../@fair/src/scss/vars";

div.org-container {
    // border: 1px solid var(--dark-bg-label);
    // border-radius: 2px;
    // background-color: var(--dark-bg);
    padding: 0.75rem;
    // margin: 0 0.5rem 0 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    width: 100%;
}

div.org {
    background-color: var(--dark-bg-secondary);
    border-radius: 2px;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    color: var(--off-white);
    cursor: pointer;
    transition: background-color 0.25s ease, border-color 0.25s ease;
    border: 1px solid transparent;
    // overflow: hidden;
}

div.org-name {
    text-overflow: ellipsis;
    overflow: hidden;
}

div.org.light {
    // box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
    box-shadow: var(--box-shadow-light);
    border: 1px solid var(--border-light);
    background-color: var(--off-white);
    color: var(--dark-gray);
}

div.org.selected {
    background-color: var(--dark-bg-tertiary);
}

div.org.selected.light {
    background-color: var(--off-white);
    border-color: var(--primary);
}

div.org:hover {
    border-color: var(--primary);
}

div.org-icon-parent {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--primary);
}

div.org-icon-parent.light {
    box-shadow: var(--box-shadow-light);
} 

div.org-icon-parent.dark {
    box-shadow: var(--box-shadow-dark);
} 

div.org-icon {
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--off-white);
}


@media (max-width: 500px) {
    div.org {
        font-size: 0.8rem
    }

    div.org-icon-parent {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: var(--primary);
        box-shadow: var(--box-shadow-light);
    }
    
    div.org-icon {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--off-white);
    }
}