.icon-button {
    pointer-events: none !important;
    margin: 0 !important;
    overflow: visible !important;
    
    .icon-button-content {
        
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        padding: 0.25rem;
        gap: 0.25rem;

        &.light > svg {
            color: #404040
        }

        &.dark > svg {
            color: #AFB3DC
        }
        div.icon-button-status {
            position: absolute;
            top: -8px;
            left: 12px;
            height: 16px;
            width: 16px;
            border-radius: 100%;
            font-size: 0.7rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #FFFFFF;
        }
    }
}


