

.section {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 0.5rem;
    border-radius: 8px;
    height: 100%;
}

.section.light {
    background-color: var(--off-white);
    border-color: var(--border-light);
    // box-shadow: 0rem 0rem 4px 0px var(--shadow-light);
    
}

.section.light:not(.no-shadow) {
    box-shadow: var(--box-shadow-light)
}

.section.dark {
    border-color: var(--primary-background-dark);
    background-color: var(--dark-bg-header)
    // box-shadow: 0rem 0rem 8px 0px var(--shadow-dark);
}

.sub-section {
    
    display: flex;
    flex-direction: column;
    // border: 1px solid;
    // padding: 0.5rem;
    border-radius: 8px;
}
