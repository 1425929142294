$primary:           #5F6BF3;
$off-white:         #FFFFFF;
$light-gray:        #B0B0B0;
$off-gray:          #7C7C7C;
$dark-gray:         #1A1A1A;

$dark-bg:           #10111C;
$dark-bg2:          #0B0C13;
$dark-bg-secondary: #1D1F34;
$dark-bg-tertiary:  #51558F;
$dark-bg-label:     #686A94;
$dark-bg-footer:    #2F3154;
$dark-bg-header:    #151726;

$light-bg-footer:   #A7A8BD;
$light-bg-header:   #F0F0F0;

$success:           #58C718;
$error:             #FF3333;
$warning:           #FABE1B;
