.spinner {
    animation:                 spin;
    animation-duration:        1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform:                 translate(-50%, -50%);
}

.align {
    position:                  absolute;
    top:                       45%;
    left:                      calc(50% - 15px);
}

.spinner.light {
    color: #51558F;
}

.spinner.dark {
    color: #51558F;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
