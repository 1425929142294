@use "vars" as vars;

$eventContainerWidth: 40rem;

div.event-container {
    background:     transparent;
    position:       fixed;
    z-index:        10000;
    width:          $eventContainerWidth;
    transition:     all vars.$themeTransition;
    // pointer-events: none;
}

div.event-container.top-left {
    top:  0;
    left: 0;
}

div.event-container.top-right {
    right: 0;
    top:   0;
}

div.event-container.bottom-left {
    left:   0;
    bottom: 0;
}

div.event-container.bottom-right {
    right:  0;
    bottom: 0;
}

div.event-item {
    position:       relative;
    font-size:      .9rem;
    letter-spacing: 1px;
    font-weight:    300;
    padding:        var(--padding);
    border-radius:  4px;
    opacity:        0;
    cursor:         pointer;
}

div.event-item.animate {
    transition: top vars.$themeTransition, bottom vars.$themeTransition, opacity vars.$themeTransition;
}

div.event-item div.event-icon svg {
    position:  absolute;
    width:     1.5rem;
    height:    1.5rem;
    top:       50%;
    left:      0.6rem;
    transform: translateY(-50%);
}

svg.event-close {
    position:  absolute;
    width:     20px;
    height:    20px;
    right:     .7rem;
    top:       50%;
    transform: translateY(-50%);
    cursor:    pointer;
    pointer-events: all;
    display:   none;
}

div.event-item.success {
    border-left: 6px solid #58C718;
}

div.event-item.warning {
    border-left: 6px solid #FABE1B;
}

div.event-item.error {
    border-left: 6px solid #FF3333;
}

div.event-item.custom {
    border-left: 6px solid transparent;
}

div.event-item.top {
    top: -3rem;
}

div.event-item.bottom {
    bottom: -1rem;
}

div.event-item.top.no-anim {
    top:     0 !important;
    opacity: 1 !important;
}

div.event-item.bottom.no-anim {
    bottom:  0 !important;
    opacity: 1 !important;
}

div.top.active {
    opacity: 1;
    top:     0;
}

div.bottom.active {
    opacity: 100%;
    bottom:  0;
}

div.event-item.light {
    background: var(--dark-bg);
    color:      #FFFFFF;

    // box-shadow:   0 0 0 1px var(--light-gray);
    // background: var(--off-white);
    // color:      var(--dark-gray);
}

div.event-item.dark {
    background: #FFFFFF;
    color:      var(--dark-bg);
}

.event-success {
    color: var(--success);
}

.event-warning {
    color: var(--warning);
}

.event-error {
    color: var(--error);
}

@media (max-width: $eventContainerWidth) {
    $eventContainerWidth: 20rem;
    div.event-container {
        width:  100%;
    }

}

