@use "../../@fair/src/scss/vars/" as vars;

.check {
    // padding: 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: color .5s ease;
}

.check.none {
    opacity: 0.5 !important;
    color: var(--dark-gray) !important;
}

.check.none.dark {
    color: var(--off-white) !important;
}

.check.up {
    // margin-right: 0.5rem;
}

// .check.down {
//     right: 1.5rem;
// }

.check.up.active {
    color: var(--success) !important;
}

.check.down.active {
    color: var(--error) !important;
}