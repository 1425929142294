div.table-action-sub-component {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: max-content;
    z-index: 10;
    pointer-events: none;
    // border: 1px solid black;
}

div.table-action-sub-component.light {
    
    border-radius: 4px;
    background-color: var(--off-white);
    box-shadow: -4px 0 8px var(--shadow-light);
}

div.table-action-sub-component.dark {
    background-color: var(--dark-bg-secondary);
    box-shadow: 0 0 0.75rem var(--dark-bg2);
}


div.table-action-sub-component.active {
    opacity: 1;
    pointer-events: all;
}

div.title-date-range.active {
    color: var(--primary)
}