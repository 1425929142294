@use "vars";

// GLOBAL BUTTON -------------------------------------------------------------------------------------------------------
button {
    cursor: pointer;
}

button.btn {
    position:       relative;
    display:        inline-block;
    border:         none;
    border-radius:  var(--border-radius);
    font-size:      .9rem;
    // padding:        var(--padding) (var(--padding) * 2);
    color:          var(--dark-bg);
    transition:     outline-color vars.$themeTransition, padding .2s ease, background vars.$themeTransition, box-shadow .2s ease, border-color vars.$themeTransition, color vars.$themeTransition, opacity .2s ease, box-shadow .2s ease;
    letter-spacing: 1px;
    font-weight:    400;
    overflow:       hidden;
    user-select:    none;
    outline:        2px solid transparent;
    // text-transform: uppercase;
}

button.btn:hover {
    opacity: 1 !important;
}

button.btn:focus {
    outline-color: var(--primary);
}

// BUTTON SIZES --------------------------------------------------------------------------------------------------------
button.sm {
    padding:        calc(var(--padding)*0.5) calc(var(--padding)*0.9);
    // padding-left: var(--padding)*2.25 !important;
    font-size:      .8rem;
    letter-spacing: .4px;
}

button.sm.icon {
    padding-left: calc(var(--padding)*2.25) !important;
}

button.md {
    padding: 17px 2rem;
    // padding: 1rem 2rem;
}

button.md.icon {
    padding: 1rem 2rem 1rem 3.3rem;
}

button.lg {
    padding: calc(var(--padding)*0.75) calc(var(--padding)*1.25) !important;
}

// BUTTON VARIANTS -----------------------------------------------------------------------------------------------------
button.filled.dark {
    background: var(--off-white);
    color:      var(--dark-bg);
}

button.filled.light {
    background: var(--dark-bg);
    color:      var(--off-white);
}

button.transparent.dark {
    background: transparent;
    color:      var(--off-white);
}

button.transparent.light {
    background: transparent;
    color:      var(--dark-bg);
}

button.outlined {
    border:     solid 1px;
    background: none;
}

button.outlined.light {
    color: var(--dark-bg);
}

button.outlined.light:hover {
    color:        var(--primary);
    border-color: var(--primary)
}

button.outlined.dark {
    color: var(--off-white);
}

button.outlined.dark:hover {
    color:        var(--light-gray);
    border-color: var(--light-gray)
}

// BUTTON TYPES --------------------------------------------------------------------------------------------------------

button.transparent.success {
    color: limegreen !important;
}

button.transparent.dark.success {
    color: limegreen !important;
}

button.transparent.dark.success .circle {
    background: limegreen !important;
}

button.transparent.light.success .circle {
    background: limegreen !important;
}

button.outlined.success {
    border-color: limegreen !important;
}

button.filled.success {
    background: limegreen !important;
}

button.filled.success.light {
    background: limegreen !important;
    color:      var(--off-white) !important;
}

button.transparent.error {
    color: var(--error) !important;
}

button.transparent.error .circle {
    background: var(--error) !important;
}

button.outlined.error {
    border-color: var(--error) !important;
    color:        var(--error) !important;
}

button.outlined.error .circle {
    background: var(--error) !important;
}

button.filled.error {
    background: var(--error) !important;
    color:      #FFFFFF !important;
}

button.transparent.warning {
    color: orange !important;
}

button.transparent.warning .circle {
    background: orange !important;
}

button.outlined.warning {
    border-color: orange !important;
    color:        orange !important;
}

button.outlined.warning .circle {
    background: orange !important;
}

button.filled.warning {
    background: orange !important;
    color:      #FFFFFF !important;
}

button.transparent.primary {
    color: var(--primary) !important;
}

button.transparent.primary .circle {
    background: var(--primary) !important;
}

button.outlined.primary {
    border-color: var(--primary) !important;
    color:        var(--primary) !important;
}

button.outlined.primary .circle {
    background: var(--primary) !important;
}

button.filled.primary {
    background: var(--primary) !important;
    color:      #FFFFFF !important;
}

button.filled.primary.dark div.circle {
    background: #FFFFFF !important;
}

button.filled.primary.light div.circle {
    background: #FFFFFF !important;
}

button.filled.light div.circle {
    background: #FFFFFF !important;
}

button.filled.dark div.circle {
    background: var(--primary) !important;
}

button.export-btn {
    margin: 0 !important;
}

// BUTTON SVG ----------------------------------------------------------------------------------------------------------
svg.spinning {
    animation:        rotation 1.2s infinite linear;
    transform-origin: center !important;
}

@keyframes rotation {
    from {
        transform: translateY(-50%) rotate(0deg);
    }
    to {
        transform: translateY(-50%) rotate(359deg);
    }
}

button.btn > svg.btn {
    position:       absolute;
    top:            50%;
    left:           1.8rem;
    transform:      translateY(-50%);
    letter-spacing: 0 !important;
}

button.btn.sm > svg.btn {
    position:       absolute;
    top:            50%;
    left:           .9rem;
    transform:      translateY(-50%);
    letter-spacing: 0 !important;
}

span.btn-end-icon svg {
}

span.btn-start-icon svg {
}

// THEME BUTTON --------------------------------------------------------------------------------------------------------
// button.theme {
//     // height:   35px !important;
//     // width:    35px !important;
//     padding:  var(--padding) * 0.5 !important;
//     position: relative;
//
//     svg {
//         position:  absolute;
//         top:       50%;
//         left:      50%;
//         transform: translate(-50%, -50%);
//     }
// }

// BUTTON FANCY CLICK --------------------------------------------------------------------------------------------------
button.btn div.circle {
    background:     var(--off-white);
    border-radius:  50%;
    position:       absolute;
    opacity:        0;
    transform:      scale(0);
    pointer-events: none;
    transition:     opacity vars.$themeTransition, transform 1.5s ease;
}

button.btn.light .circle {
    background: var(--off-white);
}

button.btn.outlined.dark .circle {
    background: var(--off-white);
}

button.btn.outlined.light .circle {
    background: var(--primary);
}

button.btn.transparent.dark .circle {
    background: var(--off-white);
}

button.btn.transparent.light .circle {
    background: var(--primary);
}

button.btn .circle.active {
    opacity: .3;
}

button.btn .circle.active-expand {
    transform: scale(4) !important;
}

button.disable-outline {
    outline: none !important;
}

// BUTTON NOTIFICATIONS -------------------------------------------------------------------------------------------------

div.btn-notif-circle {
    position:         absolute;
    // right:            0px;
    border-radius:    100%;
    background-color: rgb(255, 80, 80);
    color:            white;
    width:            16px;
    height:           16px;
    overflow:         visible !important;
    pointer-events:   none;
}

div.btn-notif-num {
    position:  absolute;
    top:       50%;
    left:      50%;
    transform: translate(-50%, -50%);
    // background-color: rgb(255, 80, 80);
    color:     white;
    font-size: .7rem;
}