@use "../../@fair/src/scss/vars";

div.footer {
    // position: relative
    position: absolute;
    bottom: 0%;
    width:    100%;
    padding:  calc(var(--padding)*0.5) 0;
    // display:  none;

    p {
        text-align:     center;
        font-size:      .8rem;
        letter-spacing: .5px;
        user-select:    none;
    }

    p.dark {
        color: var(--dark-bg)-footer;
    }

    p.light {
        color: var(--light-bg-footer);
    }
}

div.impersonation-warning {
    // display: none;
    position: absolute;
    // top: 50%;
    top: -2px;
    z-index: 150;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.impersonation-text {
    font-size: 0.85rem;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.25rem;
    padding:  .5rem 2rem;
    margin:   0.5rem;

    border-radius: var(--border-radius);
    // border: 1px solid var(--off-white);

    color: var(--off-white);
    background-color: var(--primary);
}


@media (max-width: 550px) {
    

    .impersonation-text {
        padding:  0.5rem 1rem;
    }

}

@media (max-width: 550px) {
    
    div.impersonation-warning {
        top: 34px - 24px;
    }
    
    .impersonation-text {
        font-size: 0.8rem;
        line-height: 1.0rem;
        padding:  0.5rem 1rem;
    }

}

@media (max-width: 450px) {
    
    div.impersonation-warning {
        top: unset;
        bottom: 0%;
    }

}