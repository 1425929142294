.form-toggle-group {
    display: grid;
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 200px);
}

.route-container {
    display: grid;
    grid-row-gap: 0.5em;
    grid-column-gap: 1em;
}

.form-toggle {
    div.react-toggle-track {
        width: 40px;
        height: 20px;
    }

    div.react-toggle-thumb {
        width: 18px;
        height: 18px
    }
}

.form-toggle.react-toggle--checked {
    .react-toggle-thumb {
        left: 21px;
    }
}

.form-toggle-label {
    font-size: 0.85rem;
}