@use "../../../@fair/src/scss/vars";

// div.menu-test {
//     // width: 350px;
//     // height: 100vh;
//     // overflow: auto;
//     // display: flex;
//     // flex-direction: column;
//     // row-gap: 0.25rem;
//     // padding-top: 0.25rem;
    
//     height:      100vh;
//     width:       280px;
//     min-width:   280px;
//     background:  var(--dark-bg-2);
//     position:    relative;
//     z-index:     100;
//     margin-left: -280px;
//     opacity:     0;
//     transition:  opacity 0.5s ease, margin 0.5s ease, background 0.5s ease;
//     overflow:    scroll;
//     opacity:        1 !important;
//     pointer-events: all !important;
//     margin-left:    0 !important;
// }

div.menu-test.light {
    background:  var(--dark-bg);
}

div.menu-test.dark {
    background:  var(--dark-bg-2);
}

.item-container {
    // margin: 0.5rem;
    width: 100%;
}

.item {
    padding: 1.2rem 0rem 1.2rem 1.5rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    font-size: 0.85rem;
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
    color:         #FFFFFF;
    background-color: transparent;
    transition:    background-color .2s ease;
    

    .item-content {
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        // background-color: grey;
    }
}

.item.child { 
    // color: #7a7a7a;
    color: #c6c6c6;
}
.item.child-2 { 
    // color: #c6c6c6;
    color: #e7e7e7;
}
.dropdown-arrow-container.child {
}

.item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: aquamarine ;
    
}

.item-icon > svg {
    height: 20px;
    width: 20px;
}

.item.link {
    text-decoration: none;
}

.item.hover:hover {
    color: var(--off-white);
    background-color: var(--dark-bg-secondary);
}

// .menu-dropdown-test {

// }

.menu-dropdown-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    transition:    background-color .2s ease;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.menu-dropdown-header:hover {
    color: var(--off-white);
    background-color: var(--dark-bg-secondary);
}


.menu-dropdown-content {
    // transition: scale 0.2s ease, opacity 0.2s ease, height 0.2s ease;
    // transform-origin: top;
    // scale: 1 0;
    // opacity: 0;
    pointer-events: none;
    height: 0;
    overflow: hidden;
}

.menu-dropdown-content.dropdown-active {
    // scale: 1 1;
    opacity: 1;
    pointer-events: all;
    // max-height: 100%;
    height: 100%;
}

.dropdown-arrow-container {
    padding-right: 1rem;
}


svg.dropdown-arrow {
    color: #FFFFFF;
    transition: .2s transform ease;
}

svg.dropdown-active {
    transform: rotate(180deg);
}