@use "../../@fair/src/scss/vars";

h2.break {
    font-weight: 400;
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    // padding: 0.5rem;
}

h2.dark.break {
    border-bottom-color: var(--primary-background-dark);
}