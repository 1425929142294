div.tool-tip-wrapper {
    position: relative;
    cursor: pointer;
    width: min-content;
}

div.tool-tip {
    display: none;
    // display: flex;
    position: absolute;
    // top: calc(50% - 18px);
    z-index: 1000;
    flex-direction: column;
    align-items: center;
    width: max-content;
    max-width: 300px;
    font-size: 0.8rem;
    pointer-events: none;
    // text-overflow: wrap;
    white-space: normal !important;

    div.tool-tip-arrow {
        width: 10px;
        height: 16px;
        border-style: solid;
        border-width: 7px;
        // background-color: red;
    }

    div.tool-tip-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1.0rem;
        border-radius: 4px;
        color: var(--off-white);
        // width: 100%;
    }
}

div.tool-tip-text.light  {
    background: var(--dark-gray2);
}

// div.tool-tip-arrow.light.right {
//     border-color: transparent var(--dark-gray2) transparent transparent;
// }

// div.tool-tip-arrow.light.bottom {
//     border-color: transparent transparent var(--dark-gray2) transparent;
// }

div.tool-tip-text.dark {
    background: var(--dark-bg-footer);
}

// div.tool-tip-arrow.dark.right {
//     border-color: transparent var(--dark-bg-footer) transparent transparent;
// }

// div.tool-tip-arrow.dark.bottom {
//     border-color: transparent transparent var(--dark-bg-footer) transparent;
// }

div.tool-tip.right {
    left: max(100% - 16px, 0%);
    flex-direction: row;
}

div.tool-tip.bottom {
    top: calc(100% - 10px);
    flex-direction: column;
}

$md: 800px;
$md2: 801px;

@media (max-width: 350px) {
    div.tool-tip-arrow.light.right {
        border-color: transparent;
    }
    
    div.tool-tip-arrow.light.bottom {
        border-color: transparent;
    }
    
    div.tool-tip-arrow.dark.right {
        border-color: transparent;
    }
    
    div.tool-tip-arrow.dark.bottom {
        border-color: transparent;
    }
    
}


@media (min-width: 351px) {
    div.tool-tip-arrow.light.right {
        border-color: transparent var(--dark-gray2) transparent transparent;
    }
    
    div.tool-tip-arrow.light.bottom {
        border-color: transparent transparent var(--dark-gray2) transparent;
    }
    
    div.tool-tip-arrow.dark.right {
        border-color: transparent var(--dark-bg-footer) transparent transparent;
    }
    
    div.tool-tip-arrow.dark.bottom {
        border-color: transparent transparent var(--dark-bg-footer) transparent;
    }
    
}
