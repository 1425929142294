@use "../../@fair/src/scss/vars/" as vars;

.form-select {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    padding: 1.0rem;
    // margin: 1.0rem;
    // border-bottom: 1px solid #EAEAEA;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    // justify-content: center;
}

// .form-button.light.active {
//     outline-color: var(--primary);
// }



// .form-button {
//     border: 1px solid transparent !important;
// }

.form-button {

    // -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    // -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    // box-sizing: border-box;         /* Opera/IE 8+ */

    
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    padding: 0.15rem 0.25rem;
    // margin
    font-size:      .8rem;
    letter-spacing: .4px;
    // border-radius: 2px;
    // border: 1px solid var(--light-gray);
}

.form-button-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    padding: calc(var(--padding) * 0.5) calc(var(--padding)*1.25);
    border-radius: 4px;
}

.form-button.light {
    background-color: var(--light-bg-header);
}

.form-button.dark {
    background-color: var(--dark-bg-secondary);
    // border-color: var(--dark-bg);
}

.form-button-content.light {
    background-color: var(--light-bg-header);
}

.form-button-content.dark {
    background-color: var(--dark-bg-secondary);
    // border-color: var(--dark-bg);
}

.form-button-content.light.active {
    background-color: var(--off-white);
}

.form-button-content.dark.active {
    background-color: var(--dark-bg-tertiary);
}

.form-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-left: auto
}

.form-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: auto;
}

.form-button.disabled {
    pointer-events: none;
    color: rgb(142,143,153);
    opacity: 0.8;
}

.form-button-text {
}