//     ______      _            _
//    / ____/___ _(_)_____   __(_)__ _      __
//   / /_  / __ `/ / ___/ | / / / _ \ | /| / /
//  / __/ / /_/ / / /   | |/ / /  __/ |/ |/ /
// /_/    \__,_/_/_/    |___/_/\___/|__/|__/

@use "vars" as vars;
@use "nav" as nav;
@use "code" as code;
@use "typography" as typography;
@use "button" as button;
@use "grid" as grid;
@use "input" as input;
@use "select" as select;
@use "event" as event;
@use "table" as table;
@use "color-label" as color-label;
@use "pop-up" as pop-up;

// + Global //
* {
    margin:      0;
    padding:     0;
    box-sizing:  border-box;
    font-family: "IBM Plex Sans", sans-serif;
}

body {
    background: var(--off-white);
}

body.dark {
    background: var(--dark-bg);
}

body.light {
    background: var(--off-white);
}

// Max column extend
body:after {
    content:    "12";
    display:    none;
    visibility: hidden;
}

body:before {
    content:    "xs";
    display:    none;
    visibility: hidden;
}

@media (min-width: vars.$sm) {
    body:before {
        content: "sm";
    }
}

@media (min-width: vars.$md) {
    body:before {
        content: "md";
    }
}

@media (min-width: vars.$lg) {
    body:before {
        content: "lg";
    }
}

@media (min-width: vars.$xl) {
    body:before {
        content: "xl";
    }
}

// @media (min-width: vars.$xl2) {
//     body:before {
//         content: "xl2";
//     }
// }

// @media (min-width: vars.$xl3) {
//     body:before {
//         content: "xl3";
//     }
// }

// + Reusable //
div.container {
    width:    100%;
    position: relative;
    margin:   0 auto;
}

div.logo {
    height:          60px;
    width:           60px;
    background:      url("../imgs/logo-big.png") center;
    background-size: 100%;
    border-radius:   50%;
    filter:          grayscale(100%);
    position:        absolute;
}

.dark, .light {
    transition: background vars.$themeTransition, color vars.$themeTransition;
}

.dark {
    color: var(--off-white)
}

.light {
    color: var(--dark-gray)
}

svg.theme-icon {
    transition: color vars.$themeTransition !important;
}

#navbar {
    width:         100vw;
    height:        70px;
    border-bottom: 1px solid transparent;
    transition:    border-color vars.$themeTransition;
}

#navbar.dark {
    border-color: var(--dark-bg-secondary);
}

#navbar.light {
    border-color: var(--light-gray);
}