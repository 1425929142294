@use "vars" as vars;

.light::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:       #808080;
    font-weight: 400;
    opacity:     1; /* Firefox */
    font-size:   .8rem;
}

.light:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size:   .8rem;
    font-weight: 400;
    color:       #808080;
}

.light::-ms-input-placeholder { /* Microsoft Edge */
    font-size:   .8rem;
    font-weight: 400;
    color:       #808080;
}

.dark::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:       var(--dark-bg-label);
    font-weight: 400;
    opacity:     1; /* Firefox */
    font-size:   .8rem;
}

.dark:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:       var(--dark-bg-label);
    font-weight: 400;
    font-size:   .8rem;
}

.dark::-ms-input-placeholder { /* Microsoft Edge */
    color:       var(--dark-bg-label);
    font-weight: 400;
    font-size:   .8rem;
}

// input.required::placeholder::after {
//     content: "*";
//     color: var(--primary);
//     font-weight: 800;
// }

// input.required::-ms-input-placeholder::after {
//     content: "*";
//     color: var(--primary);
//     font-weight: 800;
// }

div.input-container {
    position: relative;
}

span.required {
    color: var(--primary);
    font-weight: 800;
}

input.input, textarea.input {
    display:        inline-block;
    outline:        2px solid transparent;
    border:         0px solid transparent;
    border-radius:  var(--border-radius);
    font-size:      .9rem;
    padding:        var(--padding) calc(var(--padding) * 2);
    color:          var(--dark-gray);
    transition:     background vars.$themeTransition, outline-color vars.$themeTransition, border-color vars.$themeTransition, color vars.$themeTransition;
    letter-spacing: 1px;
    font-weight:    300;
    background:     #FFFFFF
}

input:disabled {
    color: var(--off-gray) !important;
    // cursor: not-allowed !important;
}

input.input.sm {
    font-size: .8rem !important;
}

input.error {
    outline-color: red;
}

textarea.input {
    resize:     vertical;
    max-height: 500px;
    min-height: 100px;
}

input.light, textarea.light {
    background-color: var(--light-bg-header);
}

input.dark, textarea.dark {
    background: var(--dark-bg-secondary);
    color:      var(--off-white);
    // temporary fix for google auto fill?
    color-scheme: dark;
}

.input.dark:focus, .textarea.dark:focus {
    background: var(--dark-bg-tertiary);
}

input.light, textarea.light {
    border-color: var(--light-gray);
}

.input.light:focus, .textarea.light:focus {
    // box-shadow:   0 0 0 2px var(--primary);
    outline-color: var(--primary);
    border-color:  transparent;
    background-color: var(--off-white);
}

span.input-img {
    position:   absolute;
    top:        calc(50% - 0.5px);
    transform:  translateY(-50%);
    // left:       0.5rem;
    width:      16px;
    height:     16px;
    transition: color vars.$themeTransition;
    pointer-events: none;
}

span.input-img.sm {
    left: 11px !important;
}

span.input-img-light {
    color: var(--dark-gray);
}

span.input-img-dark {
    color: white;
}

label {
    position:       relative;
    bottom:         -2px;
    display:        block;
    font-size:      .85em;
    letter-spacing: .8px;
    font-weight:    400;
    user-select:    none;
    // margin-top:     0 !important;
}

label.dark {
    color: var(--dark-bg-label);
}

label.light {
    color: var(--dark-gray);
}

label.sm {
    font-size: .8rem;
}

label.file-upload-label {
    margin-bottom: 2px !important;
}


div.input-container.checkbox {
    width: 20px;
    height: 20px;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    accent-color: var(--primary);
}

input[type="checkbox"].light {
    background-color: var(--light-bg-header);
}

input[type="checkbox"].dark {
    border: none;
}

div.input-container.sm.checkbox {
    width: 16px;
    height: 16px;
}

input.sm[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: var(--primary);
}

// Select styling
// =====================================================================================================================
div.select-wrapper {
    position: relative;
}

div.select-arrow {
    position:       absolute;
    right:          0;
    top:            55%;
    transform:      translateY(-50%);
}

select.select {
    display:        inline-block;
    outline:        none;
    border:         1px solid transparent;
    border-radius:  var(--border-radius);
    font-size:      .9rem;
    padding:        var(--padding);
    color:          var(--dark-gray);
    transition:     background vars.$themeTransition, box-shadow .2s ease, border-color vars.$themeTransition, color vars.$themeTransition;
    letter-spacing: 1px;
    font-weight:    300;
    appearance:     none;
    cursor:         pointer;
    background:     #FFFFFF
}


select.dark {
    background: var(--dark-bg-secondary);
    color:      var(--off-white)
}

select.dark:focus {
    background: var(--dark-bg-tertiary);
}

select.light {
    // border-color: var(--light-gray);
    background-color: var(--light-bg-header);
}

select.light:focus {
    box-shadow:   0 0 0 2px var(--primary);
    background-color: var(--off-white);
    border-color: transparent;
}

// Upload File Styling
// =====================================================================================================================
input[type="file"] {
    display: none !important;
}

// button.file-upload.dark {
//     background: var(--primary);
//     color: var(--off-white);
// }

// button.file-upload.light {
//     background: var(--primary);
//     color: var(--off-white);
// }

option {
    font-size: 1rem;
}

div.file-upload-metadata-wrapper {
    margin: 0.5rem;
    position: relative;
    // height:   40px;
    max-height: 40px;
    // margin:   0;
    // padding:  0;
    // display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    column-gap: 0.5rem;
    align-items: center;
    // flex-wrap: wrap;
}

.metadata-text-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;

    p {

        line-height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding:     0 !important;
        font-size:   .75rem;
        // max-width: 40px;
        max-width: min(100%, 100px);
    }
    
    // width: calc(100% - 40px);
    width: auto;
    max-height: 40px;
    // max-width: min(100%, 200px);
}

div.file-upload-metadata-wrapper p.dark {
    color: var(--light-gray);
}

div.file-upload-metadata-wrapper p.light {
    color: var(--dark-bg-tertiary);
}

div.file-upload-metadata-wrapper img {
    height:        40px;
    width:         auto;
    max-width: 60px;
    border:        1px solid var(--dark-bg-tertiary);
    float:         left;
    // margin-right:  10px;
    transition:    border-color vars.$themeTransition;
    border-radius: var(--border-radius);
    padding:       3px;
}

div.file-upload-metadata-wrapper.light img {
    border-color: var(--dark-bg-tertiary)
}

div.file-upload-metadata-wrapper svg {
    // position: relative;
    // top:      7px;
    // top: -7px;
    // left:     6px;
    cursor:   pointer;
}

div.file-upload-metadata-wrapper svg:hover {
    color: red !important;
}

div.file-upload-metadata-wrapper.dark svg {
    color: var(--off-white)
}

div.file-upload-metadata-wrapper.light svg {
    color: var(--dark-gray)
}