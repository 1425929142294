@use "../../@fair/src/scss/vars";

div.save-changes {
    z-index: 2;
    width: 100vw;
    height: auto;
    // background: #7033FF;
    background: var(--primary);
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 5px;
    padding-right: 15px;
    transition: all 0.3s ease;
}

div.save-changes-disabled { 
    opacity: 0;
    pointer-events: none;
}

div.save-changes-disabled > div > button {
    opacity: 0 !important;
    pointer-events: none !important;
}