@use "vars";

div.select-container {
    margin: 0.5rem;
}

span.required {
    color: var(--primary);
    font-weight: 800;
}

div.select-custom {
    position: relative;
    width: 100%;
    // height: 52px;
    padding: var(--padding);
    border-radius:  var(--border-radius);
    color:          var(--dark-gray);
    // transition: .5s all ease;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    // transition: border .5s ease;
    // background: var(--off-white);
    background-color: var(--light-bg-header);
    border-radius: var(--border-radius);
    transition:     background vars.$themeTransition, outline-color vars.$themeTransition, border-color vars.$themeTransition, color vars.$themeTransition;
}

div.error {
    outline-color: red !important;
}

div.select-custom, div.select-children {
    // border:         1px solid var(--light-gray);
    outline:        2px solid transparent;
}

div.select-custom.dark, div.select-children.dark {
    // border:         1px solid var(--dark-bg)
}

div.select-custom > svg.close {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    transition: transform 0.2s ease, color .5s ease;
    cursor: pointer;
    // pointer-events: none;
}

div.select-custom > svg.arrow {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease, color .5s ease;
    pointer-events: none;
}

svg.close.light {
    color:      var(--dark-gray);
}

div.select-custom > svg.small {
    width: 16px;
    height: 16px;
}

div.select-custom > svg.active {
    transform: translate(-50%, -50%) rotate(180deg) ;
}

div.select-custom.disabled > svg {
    color: var(--off-gray);
}

div.select-custom.dark {
    background: var(--dark-bg-secondary);
    color:      var(--off-white);
}

div.select-selected {
    position: relative;
    outline:        none;
    font-size:      .9rem;
    letter-spacing: 1px;
    font-weight:    300;
    width: 100%;
    height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    // padding-left: 24px;
    // transition: background 0.5s ease, outline-color 0.5s ease, border-color 0.5s ease, color 0.5s ease;
    white-space: nowrap;
    padding-right: 1rem;
}

div.select-selected.disabled {
    color: var(--off-gray);
}

div.select-selected.small {
    padding: 0 !important;
    height: 17px;
}

div.select-selected {
    position: relative;
    cursor: pointer;
}

span.select-icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    pointer-events: none;
}


// div.select-custom.dark.active {
//     background-color: var(--dark-bg-tertiary);
// }

// div.select-custom.light.active {
//     // box-shadow:   0 0 0 2px var(--primary);
//     // border-color: transparent;
// }

div.select-custom.light:focus {
    outline-color: var(--primary);
    border-color:  transparent;
    background-color: var(--off-white);
}

div.select-custom.dark:focus {
    background-color: var(--dark-bg-tertiary);
}

div.select-children {
    left: 0%;
    top: 110%;
    position: absolute;
    width: 100%;
    max-height: 200px;
    transition: top .2s ease, bottom .2s ease, opacity .2s ease;
    opacity: 1;
    border-radius:  var(--border-radius);
    z-index: 100;
    pointer-events: all;
    background: var(--off-white);
    // background-color: var(--light-bg-header);
    color:      var(--dark-gray);
    padding: 0rem !important;
    overflow: auto;
    box-shadow: 0px 0px 6px 0px var(--shadow-light);
}

div.select-children.dark {
    
    background: var(--dark-bg-secondary);
    color:      var(--off-white);
    box-shadow: 0px 0px 6px 0px var(--shadow-dark2);
}

div.select-children:not(.active) {
    top: 90%;
    opacity: 0;
    pointer-events: none;
}

div.select-child {
    padding: var(--padding);
    background: var(--off-white);
    // background-color: var(--light-bg-header);
    color:      var(--dark-gray);
    font-size: 0.9rem;
    font-weight: 300;
    transition: border-color .2s ease, background-color .2s ease;
    border: 2px solid transparent;
    border-radius: var(--border-radius);
}

div.select-child.light:hover {
    border-color: var(--primary);
}

div.select-child:focus {
    border-color: transparent;
    outline: transparent;
}

div.select-child.light:focus {
    border-color: var(--primary);
}

div.select-child.dark {
    background: var(--dark-bg-secondary);
    color:      var(--off-white)
}

div.select-child.dark:hover {
    background-color: var(--dark-bg-tertiary);
}

div.select-child.dark:focus {
    background-color: var(--dark-bg-tertiary);
}

div.small {
    padding: calc(var(--padding) / 2);
    font-size: .8rem;
}

p.select-placeholder {
    font-size:   .9rem !important;
    font-weight: 400 !important;
}

p.select-placeholder.light {
    color:       #808080 !important;
}

p.select-placeholder.dark {
    color:       var(--dark-bg-label) !important;
}

p.select-placeholder.small {
    font-size:   .8rem !important;
    font-weight: 400 !important;
}

.disabled {
    pointer-events: none !important;
}

// select search

input.search {
    // border: none !important;
    // outline: none !important;
    margin: 0 !important;
    text-overflow: ellipsis;
    padding-right: 1rem !important;
}

div.select-custom.search {
    border: none !important;
}

div.input-container.search {
    margin: 0 !important;
    // border: none !important;
    // outline: none !important;
}

div.select-custom.search {
    padding: 0 !important;
}

div.select-container.search.small {
    padding: 0 !important;
}

.base-filter {
    font-size: .6rem !important;
    min-width: 125px !important;
    max-width: 300px !important; 
    margin: 0 !important;
    padding: 0.5rem 0 0rem 0 !important;
}