// .inline-block {
//     display: inline-block
// }

// .refresh-btn {
//     margin: 0 0.5rem 0 0 !important;
// }

// .from {
//     // margin: 0 !important;
//     margin-left: 0 !important;
// }

// .single {
//     margin-left: 0 !important;
// }

.date-range {
    margin: 0 !important;
}

.refresh-btn {
    margin: 0 !important;
}

.date-preset {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid var(--light-bg-header);
    font-size: 0.8rem;
}

.date-preset-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}