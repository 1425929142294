@use "vars" as vars;

h1.typography {
    // font-family: "Roboto Mono", serif;
    font-size:   2.5rem;
    color:       var(--dark-gray);
    font-weight: 400;
    line-height: 2.4rem;
    width:       100%;
}

p.typography {
    font-size:   1rem;
    color:       var(--dark-gray);
    font-weight: 400;
    line-height: 1.5rem;
    width:       100%;
}

p.typography.sm {
    font-size: 0.85rem;
    line-height: 1rem;
}

h1.dark, p.dark {
    transition: color vars.$themeTransition;
    color:      #C3C7EE;
}

h1.light, p.light {
    transition: color vars.$themeTransition;
    color:      var(--dark-gray);
}